import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Info = makeShortcode("Info");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`1hive is a community of activists seeking to build a future that is more free, fair, open, and humane.`}</p>
    <p>{`1hive is also an economic protocol, similar to Bitcoin or Ethereum, where a digital currency, Honey, is issued and distributed programmatically. Unlike Bitcoin or Ethereum the 1hive protocol does not narrowly define the set of activities which are valuable but instead relies on community members to guide the distribution process by creating and staking on Honey distribution proposals.`}</p>
    <p>{`The goal of the 1hive protocol is to foster a healthy community economy by allocating a steady stream of Honey towards development, maintenance, and improvement of the common goods that bring the most value to the 1hive community.`}</p>
    <p>{`The growth of the 1hive economy is directly related to the communities ability to foster an inclusive and welcoming culture that people feel proud and delighted to adopt as their own. When someone choses to buy or hold Honey and participate in the Honey economy they are implicitly supporting the communities culture, values, and norms.`}</p>
    <p>{`To this end, the 1hive protocol depends on a social contract, which sets expectations for how members should engage both with the protocol and with one another.`}</p>
    <h2 {...{
      "id": "the-pledge",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-pledge",
        "aria-label": "the pledge permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Pledge`}</h2>
    <p>{`We as members, contributors, and leaders pledge to make participation in our community a harassment-free experience for everyone, regardless of age, body size, visible or invisible disability, ethnicity, sex characteristics, gender identity and expression, level of experience, education, socio-economic status, nationality, personal appearance, race, religion, or sexual identity and orientation.`}</p>
    <p>{`We pledge to act and interact in ways that contribute to an open, welcoming, diverse, inclusive, and healthy community.`}</p>
    <h3 {...{
      "id": "our-standards",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#our-standards",
        "aria-label": "our standards permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Our Standards`}</h3>
    <p>{`Examples of behavior that contributes to a positive environment for our community include:`}</p>
    <ul>
      <li parentName="ul">{`Demonstrating empathy and kindness toward other people`}</li>
      <li parentName="ul">{`Being respectful of differing opinions, viewpoints, and experiences`}</li>
      <li parentName="ul">{`Giving and gracefully accepting constructive feedback`}</li>
      <li parentName="ul">{`Accepting responsibility and apologizing to those affected by our mistakes, and learning from the experience`}</li>
      <li parentName="ul">{`Focusing on what is best not just for us as individuals, but for the overall community`}</li>
    </ul>
    <p>{`Examples of unacceptable behavior include:`}</p>
    <ul>
      <li parentName="ul">{`The use of sexualized language or imagery, and sexual attention or advances of any kind`}</li>
      <li parentName="ul">{`Trolling, insulting or derogatory comments, and personal or political attacks`}</li>
      <li parentName="ul">{`Public or private harassment`}</li>
      <li parentName="ul">{`Publishing others’ private information, such as a physical or email address, without their explicit permission`}</li>
      <li parentName="ul">{`Other conduct which could reasonably be considered inappropriate in a professional setting`}</li>
    </ul>
    <h3 {...{
      "id": "on-chain-enforcement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#on-chain-enforcement",
        "aria-label": "on chain enforcement permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`On-chain enforcement`}</h3>
    <Info mdxType="Info"> The on-chain mechanism for enforcement will depend on the completion of Celeste </Info>
    <p>{`The ability for the 1hive protocol to enforce this covenant is limited to the direct interactions that community members have with the protocol. Specifically the ability for community members to submit proposals to allocate Honey issuance or adjust protocol parameters.`}</p>
    <p>{`A community member will be required to stake Honey when submitting a proposal, attesting that the impact of the proposal could be reasonably considered to align with 1hive’s social contract. A dispute can be created by another community member if they disagree with the proposers attestation by challenging the proposal and staking an equivalent amount of Honey. If after being challenged the proposal is not withdrawn, a decentralized oracle will be used to settle the dispute.`}</p>
    <p>{`Participants in the dispute resolution protocol are expected to review the proposal, this covenant, and related past disputes in order to provide a judgement that they feel best aligns with the established norms and intention of the 1hive community.`}</p>
    <p>{`If the oracle responds in favor of the proposer the proposal will be unlocked and the challenger’s stake will be transferred to the proposer. On the other hand, if the dispute resolves in favor of the challenger the proposal will be removed from consideration and the proposers stake will be given to the challenger.`}</p>
    <h3 {...{
      "id": "off-chain-enforcement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#off-chain-enforcement",
        "aria-label": "off chain enforcement permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Off-chain enforcement`}</h3>
    <p>{`While there is no canonical off-chain forum that represents the 1hive community, there are community spaces including the 1hive Github, Discourse, and Discord communities, and others forums which may emerge in the future which are expected to adhere to this covenant.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      